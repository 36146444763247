<template>
  <div>
    <div class="reporting-period-container">
      <h3 class="pb-2">Reporting Period</h3>
      <div class="box-container">
        <div class="box-third">
          <v-select
            :items="['Monthly', 'Quarterly']"
            v-model="selectedOption"
            label="Frequency"
            outlined
            dense
            class="mr-6"
          ></v-select>
        </div>
        <div class="box-third" v-if="selectedOption === 'Monthly'">
          <v-select
            v-model="selectedMonth"
            :items="availableMonths"
            label="Month"
            item-text="name"
            item-value="value"
            outlined
            dense
            @change="getKPIsForSelectedMonth()"
          ></v-select>
        </div>
        <div class="box-third" v-else>
          <v-select
            v-model="selectedQuarter"
            :items="quarterlyMonths"
            label="Quarter"
            outlined
            dense
            @change="getQuarterlyKPIs()"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="add-kpi-btn">
      <v-btn
        class="text-none text-subtitle-1"
        color="#17BEBB"
        variant="flat"
        @click="openModal"
      >
        <span style="color: #fff">Add KPI</span>
      </v-btn>
    </div>

    <v-dialog v-model="showModal" max-width="1000" style="z-index: 9999;">
      <v-card>
        <v-btn icon @click="showModal = false" class="close-button">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="headline">KPI Entry Form </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <!-- Team Selection Dropdown -->
          <v-card-text>
            <v-select
              :items="this.kpis.map((kpi) => kpi.kpi_metric.team_name)"
              v-model="selectedTeam"
              label="Select Team"
              @change="findMetricsByTeamName(selectedTeam)"
              :rules="[(v) => !!v || 'Team is required']"
              ><template #label>
                <span class="red--text"><strong class="mr-2">*</strong></span
                >Team
              </template>
            </v-select>

            <!-- Reporting Period Dropdown -->
            <v-select
              v-model="selectedReportingDate"
              :key="selectedReportingDate"
              :items="availableMonthsForSelection"
              label="Select Reporting Period"
              :rules="[(v) => !!v || 'Reporting Period is required']"
              ><template #label>
                <span class="red--text"><strong class="mr-2">*</strong></span
                >Reporting Period
              </template>
            </v-select>
            <!-- <v-divider
              style="background-color: var(--grey)"
              v-if="selectedTeam"
            ></v-divider> -->
            <div v-for="(kpi, index) in kpisToAdd" :key="index" class="mt-6">
              <label>{{ kpi.kpi_metric.name }}</label>
              <v-text-field
                v-model="kpi.integer"
                :rules="kpi.kpi_metric ? [rules[kpi.kpi_metric.type]] : []"
                v-if="kpi.kpi_metric && kpi.kpi_metric.type === 'integer'"
                style="width: 60%"
                ><template v-slot:append-outer>
                  <v-btn class="ml-4" @click="toggleNotesVisibility(kpi)">
                    {{ kpi.showNotes ? "Hide Notes" : "Add Notes"
                    }}<v-icon v-if="!kpi.showNotes" class="ml-2"
                      >mdi-plus-circle-outline</v-icon
                    >
                  </v-btn>
                </template></v-text-field
              >

              <v-text-field
                v-model="kpi.percentage"
                :rules="kpi.kpi_metric ? [rules[kpi.kpi_metric.type]] : []"
                append-icon="%"
                style="width: 30%"
                v-else-if="
                  kpi.kpi_metric && kpi.kpi_metric.type === 'percentage'
                "
              >
                <template v-slot:append-outer>
                  <v-btn class="ml-8" @click="toggleNotesVisibility(kpi)">
                    {{ kpi.showNotes ? "Hide Notes" : "Add Notes"
                    }}<v-icon v-if="!kpi.showNotes" class="ml-2"
                      >mdi-plus-circle-outline</v-icon
                    >
                  </v-btn>
                </template>
              </v-text-field>

              <v-text-field
                v-model="kpi.decimal"
                :rules="kpi.kpi_metric ? [rules[kpi.kpi_metric.type]] : []"
                style="width: 60%"
                v-else-if="kpi.kpi_metric && kpi.kpi_metric.type === 'decimal'"
                ><template v-slot:append-outer>
                  <v-btn class="ml-4" @click="toggleNotesVisibility(kpi)">
                    {{ kpi.showNotes ? "Hide Notes" : "Add Notes"
                    }}<v-icon v-if="!kpi.showNotes" class="ml-2"
                      >mdi-plus-circle-outline</v-icon
                    >
                  </v-btn>
                </template></v-text-field
              >

              <template
                v-else-if="kpi.kpi_metric && kpi.kpi_metric.type === 'fraction'"
              >
                <!-- Render timespan input fields -->
                <div class="box-container">
                  <div class="box-quarter">
                    <v-text-field
                      v-model="kpi.numerator"
                      :label="
                        kpi.kpi_metric.team_code === 'TT'
                          ? 'Total hours'
                          : 'Number of departures'
                      "
                      class="mr-2"
                      :rules="[rules['numerator']]"
                      ><template v-slot:append-outer>
                        <v-icon large>mdi-slash-forward</v-icon>
                      </template></v-text-field
                    >
                  </div>
                  <div class="box-two-fifth">
                    <v-text-field
                      v-model="kpi.denominator"
                      :label="
                        kpi.kpi_metric.team_code === 'TT'
                          ? 'Number of tasks'
                          : 'Total number of staff'
                      "
                      class="mr-2"
                      :rules="[rules['denominator']]"
                      ><template v-slot:append-outer>
                        <v-btn class="ml-4" @click="toggleNotesVisibility(kpi)">
                          {{ kpi.showNotes ? "Hide Notes" : "Add Notes"
                          }}<v-icon v-if="!kpi.showNotes" class="ml-2"
                            >mdi-plus-circle-outline</v-icon
                          >
                        </v-btn>
                      </template></v-text-field
                    >
                  </div>
                </div>
              </template>

              <!-- Notes text field -->
              <v-textarea
                v-model="kpi.note"
                label="Note"
                v-show="kpi.showNotes"
              ></v-textarea>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn @click="createKPIs" color="#17BEBB" dark>
              <span v-if="loading">
                <v-progress-circular
                  indeterminate
                  size="20"
                  color="white"
                ></v-progress-circular>
              </span>
              <span v-else>Submit</span>
            </v-btn>
            <v-btn @click="closeModal">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbarVisible" :timeout="3000" color="#17BEBB">
      KPI created successfully!
    </v-snackbar>

    <div class="card-grid">
      <div v-for="(teamKPIs, team) in partitionedKPIs" :key="team">
        <div>
          <h3 class="pb-4">{{ team }}</h3>
        </div>
        <div class="card-container">
          <v-card
            color="transparent"
            elevation="0"
            class="pb-8"
            v-for="kpi in teamKPIs"
            :key="kpi.id"
          >
            <v-card-title
              style="color: var(--aqua);"
              class="font-weight-black text-h5 pb-0 mt-n8"
              >{{ getMetricValue(kpi) }}</v-card-title
            >
            <span>{{ kpi.kpi_metric.name }}</span>
            <div>
              <v-tooltip
                bottom
                v-if="kpi.note"
                class="dashboard-tooltip"
                max-width="50vw"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="mr-1"
                    >mdi-information-box</v-icon
                  >
                </template>
                <span>{{ kpi.note }}</span>
              </v-tooltip>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.transparent-card {
  background-color: transparent; /* Set the background color to transparent */
  box-shadow: none; /* Remove any box shadow */
  border: none; /* Remove borders if needed */
}
.card-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -8px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1;
  margin: 8px;
  box-sizing: border-box;
}

.card-container > .v-card {
  flex-basis: calc(
    24% - 16px
  ); /* Adjust flex-basis for exactly four cards per row */
  margin: 8px;
}
.dashboard-tooltip {
  z-index: 9999; /* Set a high z-index value to ensure the tooltip is above other elements */
}
@media (max-width: 1000px) {
  .card-container > .v-card {
    flex-basis: calc(50% - 16px); /* Adjust flex-basis for two cards per row */
  }
}
#kpis .v-card .v-card__title {
  padding-left: 0px;
}
.reporting-period-container {
  display: inline-block;
}

.add-kpi-btn {
  float: right;
}

@media (max-width: 1000px) {
  .add-kpi-btn {
    align-self: flex-start;
  }
}
.close-button {
  position: absolute;
  top: 1px;
  right: 1px;
}
div.settings {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
  align-items: center;
}
div.settings label {
  text-align: right;
}
</style>

<script>
export default {
  data() {
    return {
      selectedMonth: "",
      selectedQuarter: "",
      selectedOption: "Monthly",
      months: [
        { name: "January", value: 1 },
        { name: "February", value: 2 },
        { name: "March", value: 3 },
        { name: "April", value: 4 },
        { name: "May", value: 5 },
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 },
      ],
      kpis: [],
      filteredKPIs: [],
      selectedTeamMetrics: [],
      showModal: false,
      valid: true,
      loading: false,
      selectedTeam: "",
      selectedReportingDate: "",
      snackbarVisible: false,
      rules: {
        integer: (v) => !v || /^[0-9]*$/.test(v) || "Invalid integer value",
        percentage: (v) =>
          !v || /^[0-9]+(\.[0-9]+)?$/.test(v) || "Invalid percentage value",
        decimal: (v) =>
          !v || /^[0-9]+(\.[0-9]{1,2})?$/.test(v) || "Invalid decimal value",
        timespan: (v) => !v || /^[0-9]*$/.test(v) || "Invalid value",
        numerator: (v) =>
          !v || /^[0-9]+(\.[0-9]+)?$/.test(v) || "Invalid numerator value",
        denominator: (v) =>
          !v ||
          v !== 0 ||
          /^[0-9]+(\.[0-9]+)?$/.test(v) ||
          "Invalid denominator value",
      },
      kpisToAdd: [],
    };
  },
  computed: {
    availableMonths() {
      const uniqueMonths = [
        ...new Set(this.kpis.map((kpi) => kpi.reporting_date)),
      ];

      const result = uniqueMonths.map((month) => {
        const date = new Date(month);
        const monthValue = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        const monthName = this.getMonthName(monthValue - 1);
        return {
          name: `${monthName} ${year}`,
          value: monthValue,
        };
      });

      // Sort the result by the 'value' property
      result.sort((a, b) => a.value - b.value);

      return result;
    },

    partitionedKPIs() {
      const partitions = {};

      this.filteredKPIs.forEach((kpi) => {
        const teamName = kpi.kpi_metric.team_name;
        if (!partitions[teamName]) {
          partitions[teamName] = [];
        }
        partitions[teamName].push(kpi);
      });

      // Sort partitions by team name
      const sortedPartitions = {};
      Object.keys(partitions)
        .sort()
        .forEach((teamName) => {
          sortedPartitions[teamName] = partitions[teamName];
        });

      return sortedPartitions;
    },

    availableMonthsForSelection() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const months = [];

      // Generate options for the previous three months
      for (let i = 0; i < 3; i++) {
        const previousMonth = new Date(currentYear, currentMonth - i, 1);
        const year = previousMonth.getFullYear();
        const month = previousMonth.getMonth();
        const monthName = this.getMonthName(month);
        months.push(`${monthName} ${year}`);
      }

      return months;
    },

    quarterlyMonths() {
      const quarters = [];

      const quarterMappings = {
        "1": "Q1",
        "4": "Q2",
        "7": "Q3",
        "10": "Q4",
      };

      const quarterRanges = [
        [1, 2, 3],
        [4, 5, 6],
        [7, 8, 9],
        [10, 11, 12],
      ];

      for (const year of [
        ...new Set(
          this.kpis.map((kpi) => new Date(kpi.reporting_date).getUTCFullYear())
        ),
      ]) {
        for (const quarterRange of quarterRanges) {
          if (
            quarterRange.every((month) =>
              this.kpis.some((kpi) => {
                const kpiMonth = new Date(kpi.reporting_date).getUTCMonth() + 1;
                const kpiYear = new Date(kpi.reporting_date).getUTCFullYear();
                return kpiMonth === month && kpiYear === year;
              })
            )
          ) {
            const quarterText = `${quarterMappings[quarterRange[0]]} ${year}`;
            const detailedQuarterText = `${quarterText} (${this.getMonthName(
              quarterRange[0] - 1
            )} - ${this.getMonthName(quarterRange[2] - 1)})`;

            quarters.push({
              text: detailedQuarterText,
              value: quarterRange[0],
              year: year,
            });
          }
        }
      }

      return quarters;
    },
  },

  created() {
    this.getLatestKPIs();
    this.selectedReportingDate = this.availableMonthsForSelection[0];
  },
  methods: {
    getKPIsForSelectedMonth() {
      this.filteredKPIs.slice(0, this.filteredKPIs.length);
      this.$api
        .get(`kpis?reporting_date=${this.selectedMonth}`)
        .then((response) => {
          let latestKPIsMap = new Map();
          // Iterate through each KPI
          response.data.results.forEach((kpi) => {
            const metricId = kpi.metric;
            // Check if this KPI is newer than the one stored in the map
            if (
              !latestKPIsMap.has(metricId) ||
              new Date(kpi.reporting_date) >
                new Date(latestKPIsMap.get(metricId).reporting_date)
            ) {
              latestKPIsMap.set(metricId, kpi);
            }
          });
          this.filteredKPIs = Array.from(latestKPIsMap.values());
        })
        .finally(() => {
          this.selectedQuarter = "";
        });
    },

    getQuarterlyKPIs() {
      this.filteredKPIs.slice(0, this.filteredKPIs.length);
      this.$api
        .get(
          `kpis/quarterly_metrics?quarterly_reporting_dates=${this.selectedQuarter}`
        )
        .then((response) => {
          this.filteredKPIs = response.data;
        })
        .finally(() => {
          this.selectedMonth = "";
        });
    },

    getLatestKPIs() {
      this.$api
        .get("kpis")
        .then((response) => {
          this.kpis = response.data.results;
          this.selectedMonth = this.availableMonths[
            this.availableMonths.length - 1
          ].value;
          this.getKPIsForSelectedMonth();
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$router.push({ name: "403" });
          }
        });
    },

    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.selectedReportingDate = this.availableMonthsForSelection[0];
      });
      this.kpisToAdd = [];
    },

    getMetricValue(kpi) {
      if (kpi.kpi_metric.type === "timespan") {
        const parts = [
          kpi.months && `${kpi.months} m`,
          kpi.weeks && `${kpi.weeks} wk`,
          kpi.days && `${kpi.days} d`,
          kpi.hours && `${kpi.hours} hr`,
          kpi.minutes && `${kpi.minutes} min`,
        ];

        return parts.filter(Boolean).join(", ") || "-";
      } else if (kpi.kpi_metric.type === "percentage" && kpi.percentage) {
        return `${kpi.percentage * 100}%`;
      } else if (kpi.kpi_metric.type === "fraction") {
        if (kpi.numerator && kpi.denominator && kpi.denominator !== 0) {
          return `${+(kpi.numerator / kpi.denominator).toFixed(2)} hr`;
        } else if (kpi.fraction) {
          return `${+kpi.fraction.toFixed(2)} hr`;
        } else {
          return "-";
        }
      } else {
        return kpi.integer || kpi.decimal || "-";
      }
    },

    findMetricsByTeamName(teamName) {
      const uniqueMetrics = new Set();
      const emptyKPI = {
        metric: null,
        reporting_date: "",
        note: "",
        integer: null,
        decimal: null,
        percentage: null,
        months: null,
        weeks: null,
        days: null,
        hours: null,
        minutes: null,
        numerator: null,
        denominator: null,
        showNotes: false,
      };

      this.kpisToAdd = this.kpis
        .filter((kpi) => kpi.kpi_metric.team_name === teamName)
        .filter((kpi) => {
          if (!uniqueMetrics.has(kpi.kpi_metric.id)) {
            uniqueMetrics.add(kpi.kpi_metric.id);
            return true;
          }
          return false;
        })
        .map(({ kpi_metric }) => ({ kpi_metric, ...emptyKPI }))
        .sort((a, b) => a.kpi_metric.name.localeCompare(b.kpi_metric.name));
    },

    getMonthName(monthIndex) {
      const months = this.months.map((month) => month.name);
      return months[monthIndex];
    },

    createKPIs() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        for (const kpi of this.kpisToAdd) {
          kpi.metric = kpi.kpi_metric.id;
          const [monthName, year] = this.selectedReportingDate.split(" ");
          const monthNames = this.months.map(
            (month) => month.name.split(" ")[0]
          );
          const month = monthNames.indexOf(monthName) + 1; // Adjust for 1-based indexing
          // Create a Date object with the extracted month and year
          const date = new Date(year, month - 1, 1); // Month needs to be 0-based
          const formattedDate = date.toISOString();
          kpi.reporting_date = formattedDate;
          if (kpi.percentage) {
            kpi.percentage = kpi.percentage / 100;
          }
          this.$api
            .post("kpis", kpi)
            .then((response) => {
              this.snackbarVisible = true;
              this.closeModal();
              this.getLatestKPIs();
            })
            .catch((error) => {
              console.error("Error creating KPI:", error);
            })
            .finally(() => {
              this.loading = false;
              this.selectedOption = "Monthly";
            });
        }
      }
    },
    toggleNotesVisibility(kpi) {
      kpi.showNotes = !kpi.showNotes;
    },
  },
};
</script>
