<template>
  <div class="container">
    <div class="container content">
      <div class="box-container title-box">
        <v-icon
          class="mdi mdi-chart-box"
          size="90"
          color="var(--aqua)"
        ></v-icon>
        <div class="title-page">
          <h1 class="name mt-3 pl-8">
            CGEPI Operations Performance Management Dashboard
          </h1>
        </div>
      </div>
      <button
        class="tablink tablinkhalf"
        v-on:click="setTab('kpis')"
        v-bind:class="{ active: this.activeTab === 'kpis' }"
      >
        KPIs
      </button>
      <button
        class="tablink tablinkhalf"
        v-on:click="setTab('files')"
        v-bind:class="{ active: this.activeTab === 'files' }"
      >
        Files
      </button>
      <div id="kpis" class="tabcontent" v-show="this.activeTab === 'kpis'">
        <dashboard />
      </div>

      <div id="files" class="tabcontent" v-show="this.activeTab === 'files'">
        <file-explorer
          :filesEndPoint="'attachments?team_code=EX'"
          :foldersEndPoint="'folders?team_code=EX'"
          :accessGroup="'Executives'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FileExplorer from "../components/FileExplorer.vue";
import Dashboard from "../components/Dashboard.vue";
export default {
  components: {
    Dashboard,
    FileExplorer,
  },
  title() {
    return `CGEPI Dashboard`;
  },
  data() {
    return {
      activeTab: "kpis",
    };
  },
  created() {},
  computed: {},
  methods: {
    setTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
